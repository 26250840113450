@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.tree-container {
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-lg);
  background-color: var(--color-element-layer1);

  &:empty {
    display: none;
  }

  ::v-deep .item:not(.expanded):hover {
    background-color: var(--color-control-background-theme);
  }

  ::v-deep .list-container {
    height: auto;
  }

  ::v-deep .inner-item {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: 8px;
    }
  }

  ::v-deep .item.expanded {
    // items within primary color
    .v-icon {
      color: White;
    }
  }
}

.action-container {
  display: flex;
  margin-bottom: 20px;

  .v-input {
    margin-right: auto;
  }
}
